function initSlideDown(triggerSelector, elementToSlideSelector) {
  let open = false
  
  const triggerEl = document.querySelector(triggerSelector)
  const elementToSlideEl = document.querySelector(elementToSlideSelector)
  
  if(!triggerEl || !elementToSlideEl) {
    console.log('some elements missings')
    console.log(triggerSelector, triggerEl)
    console.log(elementToSlideSelector, elementToSlideEl)
    return
  }
  
  function updateSlideDown() {
    const duration = 400
    
    if(open) {
      elementToSlideEl.style.display = 'block'
      elementToSlideEl.style.overflow = 'hidden'
      elementToSlideEl.style.height = '0px'
      const height = elementToSlideEl.scrollHeight
      const step = Math.ceil(height / (duration / 16))
      let currentHeight = 0
      const interval = setInterval(() => {
        currentHeight += step
        if (currentHeight >= height) {
          clearInterval(interval)
          currentHeight = height
          elementToSlideEl.style.height = ''
          elementToSlideEl.style.overflow = ''
        }else {
          elementToSlideEl.style.height = currentHeight + 'px'
        }        
      }, 16)
    }else {
      elementToSlideEl.style.overflow = 'hidden'
      const height = elementToSlideEl.scrollHeight
      const step = Math.ceil(height / (duration / 16))
      let currentHeight = height
      const interval = setInterval(() => {
        currentHeight -= step
        if (currentHeight <= 0) {
          clearInterval(interval)
          currentHeight = 0
          elementToSlideEl.style.display = 'none'
          elementToSlideEl.style.overflow = ''
        }
        elementToSlideEl.style.height = currentHeight + 'px'
      }, 16)

    }
    
  }
  
  function rotateIcon() {
    const elToRotate = triggerEl.querySelector('.trigger-rotate-icon')
    console.log('elToRotate', elToRotate)
    if(!elToRotate) return
    if(open) {
      elToRotate.classList.add('rotate-180')
    }else {
      elToRotate.classList.remove('rotate-180')
    }
  }
  
  triggerEl.onclick = function(e) {
    e.preventDefault()
    
    open = !open
    
    updateSlideDown()
    
    rotateIcon()
    
  }
  
}

window.initSlideDown = initSlideDown

function initAllSlideDowns() {
  const triggers = document.querySelectorAll('.slide-down-trigger')
  triggers.forEach(function(trigger) {
    // const elementToSlide = trigger.nextElementSibling
    const elementToSlide = trigger.parentNode.querySelector('.slide-down-el')
    if(!elementToSlide) return
    
    const triggerSelector = `#${trigger.id}`
    const elementToSlideSelector = `#${elementToSlide.id}`
    
    initSlideDown(triggerSelector, elementToSlideSelector)
  })
  
}

window.addEventListener('load', function() {
  initAllSlideDowns()
})