window.scrollToPosition = function a(targetPosition, duration) {
  const startPosition = window.pageYOffset;
  const distance = targetPosition - startPosition;
  const startTime = performance.now();

  function scroll(timestamp) {
    const currentTime = timestamp - startTime;
    const progress = Math.min(currentTime / duration, 1);
    const easeProgress = 0.5 - 0.5 * Math.cos(progress * Math.PI);

    window.scrollTo(0, startPosition + distance * easeProgress);

    if (currentTime < duration) {
      requestAnimationFrame(scroll);
    }
  }

  requestAnimationFrame(scroll);
}

window.scrollToElment = function a(id, duration = 800, ignoreState = false) {
  const el = document.getElementById(id)
  if(!el) return
  const position = el.getBoundingClientRect().top + (document.documentElement.scrollTop || window.scrollY)
  if(!ignoreState) {
    window.history.pushState(id, '', `#${id}`)
  }
  scrollToPosition(position, duration)
}