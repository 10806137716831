import '../css/global.scss'

import Alpine from 'alpinejs';

window.Alpine = Alpine;

Alpine.store('defaultSelectedSuburbs', {
  data: [],
  setData(incomingData) {
    this.data = incomingData
  },
  removeData() {
    this.data = []
  }
})

Alpine.start();
 
window.doneInitAlpine = true

import './scripts/helpers'
import './scripts/init-hero'
import './scripts/slide-down'

import './scripts/common-swiper'
import './scripts/plyr'

import './scripts/bar-chart'
import './scripts/swiper-slider'





