function createBars(barContainer, percentage) {
  const accentColor = barContainer.getAttribute('data-accent')
  let height = 15;
  for (let i = 0; i < 20; i++) {
    const bar = document.createElement('div');
    bar.classList.add('bar');
    
    if ( i < percentage / 5 && !(i+1 < percentage / 5) && percentage % 5 !== 0 ) {
      const remainder = percentage % 5
      
      bar.classList.add('partial-accent');
      const leftPercentage = (remainder/5) * 100
      
      const left = document.createElement('div')
      left.classList.add('before');
      left.style.width = `${leftPercentage}%`
      left.style.backgroundColor = accentColor
      
      const right = document.createElement('div')
      right.classList.add('after');
      right.style.width = `${100 - leftPercentage}%`
      
      bar.appendChild(left)
      bar.appendChild(right)
    }else if (i < percentage / 5) {
      bar.classList.add('bar-accent');
      bar.style.backgroundColor = accentColor
    }
    barContainer.appendChild(bar);
    bar.style.height = `${height}px`
    height += 1
  }
}

window.addEventListener("load", function() {
  const barContainers = document.querySelectorAll('.bar-container');
  barContainers.forEach(function(barContainer) {
    const percentage = parseInt( barContainer.getAttribute('data-percentage') )
    createBars(barContainer, percentage);
  })
});