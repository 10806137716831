document.addEventListener('DOMContentLoaded', function () {
    var swiper = new Swiper('.swiper-container', {
      loop: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      allowTouchMove: true,
      effect: 'fade',
      fadeEffect: {
        crossFade: true,
      },
      on: {
        slideChange: function () {
          swiperAutoplayVideos(this);
        },
      },
      on: {
        slideChangeTransitionStart: function () {
          $('.swiper-slide-content-container').hide(0);
          $('.swiper-slide-content-container').removeClass('aos-init').removeClass('aos-animate');
        },
        slideChangeTransitionEnd: function () {
          $('.swiper-slide-content-container').show(0);
          AOS.init();
        },
      } 
    });
   
    function swiperAutoplayVideos(swiper) {
      var slides = swiper.slides;
      slides.forEach(slide => {
        var video = slide.querySelector('video.swiper-slide-video');
        if (video) {
          video.pause();
          video.currentTime = 0;
        }
      });
  
      var activeSlide = swiper.slides[swiper.activeIndex];
      var video = activeSlide.querySelector('video.swiper-slide-video');
      if (video) {
        video.play();
      }
    }
  });
  